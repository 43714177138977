import { useState } from 'react';
import styles from './CreatePurchase.module.css';

import ClientService from '../services/client';
import PurchaseService from '../services/purchase';

function CreatePayment({ clientId, totalDue }) {
  const [value, setValue] = useState();
  
  const Client = new ClientService();
  const Purchase = new PurchaseService();

  async function addPayment() {
    await Client.update(clientId, {totalDue: Number(totalDue) - Number(value)});
    await Purchase.deleteAll(clientId);

    if (value < totalDue) {
      await Purchase.create(clientId, {
        name: 'Restante',
        value: Number(totalDue) - Number(value)
      });
    }
    
    window.location.reload();
  }

  return (
    <div className={styles.container}>
      <input
        type='number'
        placeholder='Insira o valor do pagamento'
        onChange={(e) => setValue(e.target.value)}
      />
      <button onClick={addPayment}>Concluir</button>
    </div>
  );
}

export default CreatePayment;
