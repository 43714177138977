import { useState } from 'react';
import styles from './CreatePurchase.module.css';

import ClientService from '../services/client';
import PurchaseService from '../services/purchase';

function CreatePurchase({ clientId, totalDue }) {

  const [name, setName] = useState();
  const [value, setValue] = useState();
  
  const Client = new ClientService();
  const Purchase = new PurchaseService();

  async function addPurchase() {
    await Purchase.create(clientId, {
      name: name,
      value: value,
    });
    
    await Client.update(clientId, {totalDue: Number(totalDue) + Number(value)});
    
    window.location.reload();
  }

  return (
    <div className={styles.container}>
      <input
        type='text'
        placeholder='Insira o nome do produto'
        onChange={(e) => setName(e.target.value)}
      />
      <input
        type='number'
        placeholder='Insira o valor do produto'
        onChange={(e) => setValue(e.target.value)}
      />
      <button onClick={addPurchase}>Concluir</button>
    </div>
  );
}

export default CreatePurchase;
