import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Home from './components/pages/Home';
import Client from './components/pages/Client';
import CreateClient from './components/pages/CreateClient';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/:id' element={<Client />} />
        <Route path='/createclient' element={<CreateClient />} />
      </Routes>
    </Router>
  );
}

export default App;
