import { Link } from 'react-router-dom';
import styles from './ClientCard.module.css';

function ClientCard(props) {
    return (
        <div className={styles.client_card}>
            <h4>{props.name}</h4>
            <p><span>Total: </span>{props.totalDue.toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}</p>
            <Link to={`/${props.id}`}>Ver Cliente</Link>
        </div>
    );
}

export default ClientCard;