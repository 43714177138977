import { Link, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import styles from './Client.module.css';
import PurchaseCard from '../layout/purchase/PurchaseCard';
import DeleteClient from './DeleteClient';
import CreatePurchase from './CreatePurchase';
import CreatePayment from './CreatePayment';

import ClientService from '../services/client';
import PurchaseService from '../services/purchase';

function Client() {
  const { id } = useParams();

  const [client, setClient] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [deleteClient, setDeleteClient] = useState(false);
  const [createPurchase, setCreatePurchase] = useState(false);
  const [createPayment, setCreatePayment] = useState(false);
  
    useEffect(() => {
      const Client = new ClientService();
      const Purchase = new PurchaseService();
      async function fetchData() {
          var response_client = await Client.getById(id);
          setClient(response_client);

          var response_purchase = await Purchase.getAll(id);
          setPurchases(response_purchase);
      }
      fetchData();
    }, [id]);

  function toggleDeleteForm() {
    setDeleteClient(!deleteClient);
  }

  function toggleCreatePurchase() {
    setCreatePurchase(!createPurchase);
    setCreatePayment(false);
  }

  function toggleCreatePayment() {
    setCreatePayment(!createPayment);
    setCreatePurchase(false);
  }

  return (
    <div className={styles.container}>
      <h1>{client.name}</h1>
      <h3>{Number(client.totalDue).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}</h3>
      <h5>ID: {client._id}</h5>
      <div className={styles.buttons}>
        <div className={styles.blue_button}>
          <Link onClick={toggleCreatePurchase}>Compra</Link>
        </div>
        <div className={styles.green_button}>
          <Link onClick={toggleCreatePayment}>Pagamento</Link>
        </div>
      </div>
      {createPurchase && (
        <div>
          <h3>Adicionar Compra</h3>
          <CreatePurchase clientId={id} totalDue={client.totalDue} />
        </div>
      )}
      {createPayment && (
        <div>
          <h3>Adicionar Pagamento</h3>
          <CreatePayment clientId={id} totalDue={client.totalDue} />
        </div>
      )}
      <h3>Lista de compras</h3>
      {purchases.length > 0 ? (
        purchases.map((purchase) => (
          <PurchaseCard
            key={purchase._id}
            purchase={purchase}
            client={client}
          />
        ))
      ) : (
        <p>Sem compras cadastradas.</p>
      )}
      {deleteClient && (
        <DeleteClient
          toggleDeleteForm={toggleDeleteForm}
          clientId={client._id}
        />
      )}
      {!deleteClient && (
        <div className={styles.buttons}>
          <div className={styles.red_button}>
            <button onClick={toggleDeleteForm}>Deletar Cliente</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Client;
