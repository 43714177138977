import { useState } from 'react';
import styles from './PurchaseCard.module.css';

import ClientService from '../../services/client';
import PurchaseService from '../../services/purchase';

function PurchaseCard({ purchase, client }) {
    const date = new Date(purchase.date);
    const day = date.getDate().toString().length === 2 ? date.getDate().toString() : ('0' + date.getDate());
    const month = (date.getMonth()+1).toString().length === 2 ? (date.getMonth()+1).toString() : ('0' + (date.getMonth()+1));
    const purchaseDate = `${day}/${month}/${date.getFullYear()}`
    
    const [deletePurchaseForm, setDeletePurchaseForm] = useState(false);

    const Client = new ClientService();
    const Purchase = new PurchaseService();

    function toggleDelete() {
        setDeletePurchaseForm(!deletePurchaseForm);
    }

    async function deletePurchase() {
        await Purchase.deleteById(purchase._id);
        await Client.update(client._id, {totalDue: Number(client.totalDue) - Number(purchase.value)});
        window.location.reload();
    }

    return (
        <div className={styles.purchase_card}>
            <h4>{purchase.name}</h4>
            <p className={styles.purchase_card_p}><span>Valor: </span>{purchase.value.toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}</p>
            <p><span>Data: </span> {purchaseDate}</p>
            
            {!deletePurchaseForm && (<button onClick={toggleDelete} className={styles.button_red}>Deletar compra</button>)}
            {deletePurchaseForm && (
                <div>
                    <p>Deseja deletar esta compra?</p>
                    <button onClick={deletePurchase} className={styles.button_red}>Sim</button>
                    <button onClick={toggleDelete} className={styles.button_blue}>Não</button>
                </div>
            )}
            <br />
        </div>
    );
}

export default PurchaseCard;