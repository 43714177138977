import axios from "axios";

class ClientService {
  async create(data) {
    await axios.post('https://api-material.onrender.com/client', data, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  async update(clientId, data) {
    	await axios.patch(`https://api-material.onrender.com/client/${clientId}`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  }

  async get() {
    const response = await axios.get('https://api-material.onrender.com/client');
    return response.data;
  }

  async getById(id) {
    const response = await axios.get(`https://api-material.onrender.com/client/${id}`);
    return response.data;
  }

  async deleteById(id) {
    await axios.delete(`https://api-material.onrender.com/client/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}

export default ClientService;