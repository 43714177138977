import styles from './CreateClient.module.css';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ClientService from '../services/client';

function CreateClient() {
    const navigate = useNavigate();

    const [client, setClient] = useState({});
    const Client = new ClientService();

    function handleChange(e) {
        setClient({...client, [e.target.name]: e.target.value});
    }
    
    async function createPost(data) {
        await Client.create(data);
        navigate('/');
    }
    
    function submit(e) {
        e.preventDefault();
        createPost(client);
    }

    return(
        <section className={styles.container}>
            <h1>Adicionar cliente</h1>
            <br/>
            <form onSubmit={submit}>
                <input type="text" placeholder="Insira o nome do cliente" name="name" id="name" onChange={handleChange} />
                <button>Criar</button>
            </form>
        </section>
    );
}

export default CreateClient;