import { useNavigate } from 'react-router-dom';
import styles from './DeleteClient.module.css';

import ClientService from '../services/client';
import PurchaseService from '../services/purchase';

function DeleteClient({ toggleDeleteForm, clientId }) {
  const navigate = useNavigate();
  
  const Client = new ClientService();
  const Purchase = new PurchaseService();

  async function deleteClient() {
    await Client.deleteById(clientId);
    await Purchase.deleteAll(clientId);

    navigate('/');
  }

  return (
    <div className={styles.container}>
      <br />
      <h4>Tem certeza que deseja deletar esse cliente?</h4>
      <div className={styles.buttons}>
        <div className={styles.red_button}>
          <button onClick={deleteClient}>Deletar</button>
        </div>
        <div className={styles.blue_button}>
            <button onClick={toggleDeleteForm}>Cancelar</button>
        </div>
      </div>
    </div>
  );
}

export default DeleteClient;
