import { useEffect, useState } from "react";

import styles from './Home.module.css';
import ClientCard from "../layout/client/ClientCard";

import ClientService from '../services/client';

function Home() {
    const [clients, setClients] = useState([]);

    useEffect(() => {
        const Client = new ClientService();
        async function fetchData() {
            const response = await Client.get()
            setClients(response);
        }
        fetchData();
    }, []);
    
    
    return (
        <section className={styles.home_container}>
            <h1>Todos os clientes ({clients.length})</h1>
            {clients.map(client => (
                <ClientCard key={client._id} id={client._id} name={client.name} totalDue={client.totalDue}/>
            ))}
        </section>
    );
}

export default Home;