import styles from './Navbar.module.css';
import { Link } from 'react-router-dom';

import logo from '../../img/logo.jpg';

function Navbar() {
    return (
        <nav className={styles.navbar}>
              <Link to="/"><img src={logo} alt="Material de construção" height="64" /></Link>
              <ul className={styles.list}>
                  <li className={styles.item}>
                      <Link to="/createclient">Adicionar Cliente</Link>
                  </li>
              </ul>
        </nav>
    );
}

export default Navbar;