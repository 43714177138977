import axios from "axios";

class PurchaseService {
  async create(clientId, data) {
    await axios.post(`https://api-material.onrender.com/purchase/${clientId}`, data, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
  }

  async getById(id) {
    const response = await axios.get(`https://api-material.onrender.com/purchase/${id}`);
    return response.data;
  }

  async getAll(clientId) {
    const response = await axios.get(`https://api-material.onrender.com/purchase/${clientId}`);
    return response.data;
  }

  async deleteById(id) {
    await axios.delete(`https://api-material.onrender.com/purchase/${id}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async deleteAll(clientId) {
    await axios.delete(`https://api-material.onrender.com/purchase/${clientId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        deleteAllForClient: true,
      },
    });
  }
}

export default PurchaseService;